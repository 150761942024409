import React from 'react'
import Header from './components/Header'
import Board from './components/Board'
const App = () => {
  return (
    <>
      <Header/>
      <Board/>
    </>
  )
}

export default App