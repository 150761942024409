import React from 'react'

const Header = () => {
  return (
    <div className='header'>
        <h1>Angie & Chester 💕</h1>
    </div>
  )
}

export default Header


//replace header title