const polaroidListData = [
    {
        id: 0,
        title: 'title',
        img: 'image-0.jpg'
    },
    {
        id: 1,
        title: 'title',
        img: 'image-1.jpg'
    },
    {
        id: 2,
        title: 'title',
        img: 'image-2.jpg'
    },
    {
        id: 3,
        title: 'title',
        img: 'image-3.jpg'
    },
    {
        id: 4,
        title: 'title',
        img: 'image-4.jpg'
    },
    {
        id: 5,
        title: 'title',
        img: 'image-5.jpg'
    },
    {
        id: 6,
        title: 'title',
        img: 'image-6.jpg'
    },
    {
        id: 7,
        title: 'title',
        img: 'image-7.jpg'
    },
    {
        id: 8,
        title: 'title',
        img: 'image-8.jpg'
    },
    {
        id: 9,
        title: 'title',
        img: 'image-9.jpg'
    },
    {
        id: 10,
        title: 'title',
        img: 'image-10.jpg'
    },
    {
        id: 11,
        title: 'title',
        img: 'image-11.jpg'
    },
    {
        id: 12,
        title: 'title',
        img: 'image-12.jpg'
    },
    {
        id: 13,
        title: 'title',
        img: 'image-13.jpg'
    },
    {
        id: 14,
        title: 'title',
        img: 'image-14.jpg'
    },
    {
        id: 15,
        title: 'title',
        img: 'image-15.jpg'
    },
    {
        id: 16,
        title: 'title',
        img: 'image-16.jpg'
    },
    {
        id: 17,
        title: 'title',
        img: 'image-17.jpg'
    },
    {
        id: 18,
        title: 'title',
        img: 'image-18.jpg'
    },
    {
        id: 19,
        title: 'title',
        img: 'image-19.jpg'
    },
    {
        id: 20,
        title: 'title',
        img: 'image-20.jpg'
    },
    {
        id: 21,
        title: 'title',
        img: 'image-21.jpg'
    },
    {
        id: 22,
        title: 'title',
        img: 'image-22.jpg'
    },
    {
        id: 23,
        title: 'title',
        img: 'image-23.jpg'
    },
    {
        id: 24,
        title: 'title',
        img: 'image-24.jpg'
    },
    {
        id: 25,
        title: 'title',
        img: 'image-25.jpg'
    },
    {
        id: 26,
        title: 'title',
        img: 'image-26.jpg'
    },
    {
        id: 27,
        title: 'title',
        img: 'image-27.jpg'
    },
    {
        id: 28,
        title: 'title',
        img: 'image-28.jpg'
    },
    {
        id: 29,
        title: 'title',
        img: 'image-29.jpg'
    },
    {
        id: 30,
        title: 'title',
        img: 'image-30.jpg'
    },
    {
        id: 31,
        title: 'title',
        img: 'image-31.jpg'
    },
    {
        id: 32,
        title: 'title',
        img: 'image-32.jpg'
    },
    {
        id: 33,
        title: 'title',
        img: 'image-33.jpg'
    },
    {
        id: 34,
        title: 'title',
        img: 'image-34.jpg'
    },
    {
        id: 35,
        title: 'title',
        img: 'image-35.jpg'
    },
    {
        id: 36,
        title: 'title',
        img: 'image-36.jpg'
    },
    {
        id: 37,
        title: 'title',
        img: 'image-37.jpg'
    },
    {
        id: 38,
        title: 'title',
        img: 'image-38.jpg'
    },
    {
        id: 39,
        title: 'title',
        img: 'image-39.jpg'
    },
    {
        id: 40,
        title: 'title',
        img: 'image-40.jpg'
    },
    {
        id: 41,
        title: 'title',
        img: 'image-41.jpg'
    },
    {
        id: 42,
        title: 'title',
        img: 'image-42.jpg'
    },
    {
        id: 43,
        title: 'title',
        img: 'image-43.jpg'
    },
    {
        id: 44,
        title: 'title',
        img: 'image-44.jpg'
    },
    {
        id: 45,
        title: 'title',
        img: 'image-45.jpg'
    },
    {
        id: 46,
        title: 'title',
        img: 'image-46.jpg'
    },
    {
        id: 47,
        title: 'title',
        img: 'image-47.jpg'
    },
    {
        id: 48,
        title: 'title',
        img: 'image-48.jpg'
    },
    {
        id: 49,
        title: 'title',
        img: 'image-49.jpg'
    },
    {
        id: 50,
        title: 'title',
        img: 'image-50.jpg'
    },
    {
        id: 51,
        title: 'title',
        img: 'image-51.jpg'
    },
    {
        id: 52,
        title: 'title',
        img: 'image-52.jpg'
    },
    {
        id: 53,
        title: 'title',
        img: 'image-53.jpg'
    },
    {
        id: 54,
        title: 'title',
        img: 'image-54.jpg'
    },
    {
        id: 55,
        title: 'title',
        img: 'image-55.jpg'
    },
    {
        id: 56,
        title: 'title',
        img: 'image-56.jpg'
    },
    {
        id: 57,
        title: 'title',
        img: 'image-57.jpg'
    },
    {
        id: 58,
        title: 'title',
        img: 'image-58.jpg'
    },
    {
        id: 59,
        title: 'title',
        img: 'image-59.jpg'
    },
    {
        id: 60,
        title: 'title',
        img: 'image-60.jpg'
    },
    {
        id: 61,
        title: 'title',
        img: 'image-61.jpg'
    },
    {
        id: 62,
        title: 'title',
        img: 'image-62.jpg'
    },
    {
        id: 63,
        title: 'title',
        img: 'image-63.jpg'
    },
    {
        id: 64,
        title: 'title',
        img: 'image-64.jpg'
    },
    {
        id: 65,
        title: 'title',
        img: 'image-65.jpg'
    },
    {
        id: 66,
        title: 'title',
        img: 'image-66.jpg'
    },
    {
        id: 67,
        title: 'title',
        img: 'image-67.jpg'
    },
    {
        id: 68,
        title: 'title',
        img: 'image-68.jpg'
    },
    {
        id: 69,
        title: 'title',
        img: 'image-69.jpg'
    },
    
    
];

export default polaroidListData;