import React from 'react'
import PolaroidListItem from './PolaroidListItem'
import polaroidListData from '../data'
import stickerList from '../sticker-data'

const PolaroidList = () => {

  const shuffledPolaroidData = shuffle(polaroidListData); 

  return (
    <>
        {
          shuffledPolaroidData.map((polaroidData, index)=>{
            const sticker1 = Math.floor(Math.random()*stickerList.length);
            const sticker2 = Math.floor(Math.random()*stickerList.length);
            return(
              <div key={polaroidData.id} className='polaroid-item-container'>
                <PolaroidListItem polaroidData={polaroidData} index={index}/>
                <div className='sticker'>
                  <img src={'static/stickers/'+stickerList[sticker1].img} alt='ss'/>
                  <img src={'static/stickers/'+stickerList[sticker2].img} alt='ss'/>
                </div>
              </div>
            )
          })
        }
    </>
  )
}

export default PolaroidList

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}