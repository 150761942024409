const stickerList = [
    {
        img: 'sticker-1.png'
    },
    {
        img: 'sticker-2.png'
    },
    {
        img: 'sticker-3.png'
    },
    {
        img: 'sticker-4.png'
    },
    {
        img: 'sticker-5.png'
    },
    {
        img: 'sticker-6.png'
    },
    {
        img: 'sticker-7.png'
    },
    {
        img: 'sticker-8.png'
    },
    {
        img: 'sticker-9.png'
    },
    {
        img: 'sticker-10.png'
    },
    {
        img: 'sticker-11.png'
    },
    {
        img: 'sticker-12.png'
    },
    {
        img: 'sticker-13.png'
    },
    {
        img: 'sticker-14.png'
    },
    {
        img: 'sticker-15.png'
    },
    {
        img: 'sticker-16.png'
    },
    {
        img: 'sticker-17.png'
    },
    {
        img: 'sticker-18.png'
    },
    {
        img: 'sticker-19.png'
    },
]

export default stickerList